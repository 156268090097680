import React from 'react';
import Footer from '../components/Footer'
import {Link} from 'react-router-dom';
import Balance from '../components/PieChart/Balance';
import {Paper, Avatar, Box, Button, Grid2, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import {fetchBalance} from '../hooks/fetchData'
import {useTranslation} from 'react-i18next';
import PriceCheck from "@mui/icons-material/PriceCheck";
import {USDollar} from "../hooks/format";
import ResponsiveAppBar from "../components/ResponsiveAppBar";

export default function Profile() {
    const [available, setAvailable] = React.useState('');
    const [cashFlow, setCashFlow ] = React.useState('');
    const [longTerm, setLongTerm ] = React.useState('');
    const [balance, setBalance] = React.useState('');

    const [name, setName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [assets, setAssets] = React.useState([]);
    const [depositLimit, setDepositLimit] = React.useState(true );
    const [transactions, setTransactions ] =React.useState([]);
    const [accNumber, setAccNumber] = React.useState('');

    //const [admin, setAdmin] = React.useState(false);

    const {t} = useTranslation();

    React.useEffect( () => {
        fetchBalance().then(data => {
            if (!data.error){
                //console.log(data)
                setAvailable(data.available);
                setLongTerm(data.long_term);
                setCashFlow(data.cash_flow);
                setBalance(data.balance);
                setAssets(data.assets);
                setName(data.name);
                setLastName(data.lastName);
                setDepositLimit(data.deposit_limit);
                setTransactions(data.transactions);
                setAccNumber(data.account_number);
                //setAdmin(data.admin);
            }

        }).catch( error => {
            console.log(error)
        });
    }, [])

    return (<div style={{backgroundColor: "#ededed"}}>
            <ResponsiveAppBar/>
            <Box display="flex" justifyContent="center">
                <Box flexGrow={1} sx={{maxWidth: 1000, minHeight: 'calc(100vh - 180px)', paddingTop: 2, paddingBottom: 2}}>
                    <Paper sx={{padding:1, margin: 1}} elevation={2}>

                    <Grid2 container spacing={2}>
                        <Grid2 size={{xs: 12, sm: 12, md: 6, lg:6, xl:6}}>
                            <Box p={2}>
                                <Typography variant='h3'>
                                    {`${name} ${lastName}`}
                                </Typography>

                                <hr style={{
                                    border: "2px solid #032A51",
                                }}/>

                                <Typography fontWeight="bold">
                                    {`SHN-${accNumber}`}
                                </Typography>
                            </Box>

                            <Box p={2}>
                                <Typography variant='h5'>
                                    {t("dashboard_available")}
                                </Typography>
                                <Typography variant='h5' fontWeight='bold' gutterBottom>
                                    {USDollar.format(available)} USD
                                </Typography>

                                <Typography variant='h5'>
                                    {t("dashboard_cash_flow")}
                                </Typography>
                                <Typography variant='h5' fontWeight='bold' gutterBottom>
                                    {USDollar.format(cashFlow)} USD
                                </Typography>

                                <Typography variant='h5'>
                                    {t("dashboard_long_term")}
                                </Typography>
                                <Typography variant='h5' fontWeight='bold' gutterBottom>
                                    {USDollar.format(longTerm)} USD
                                </Typography>

                                <Typography variant='h5'>
                                    {t("dashboard_balance")}
                                </Typography>
                                <Typography variant='h5' fontWeight='bold' gutterBottom>
                                    {USDollar.format(balance)} USD
                                </Typography>
                            </Box>

                            <Box display='flex' flexDirection='row' flexWrap="wrap" p={1}>
                                <Box p={1}>
                                    <Button component={Link} to="/fund" sx={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '110px'}}>
                                        {t("dashboard_fund")}
                                    </Button>
                                </Box>
                                <Box p={1}>
                                    <Button component={Link} to='/invest' sx={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '110px'}}>
                                        {t("dashboard_invest")}
                                    </Button>
                                </Box>
                                <Box p={1}>
                                    <Button component={Link} to="/transfer" sx={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '110px'}}>
                                        {t("dashboard_transfer")}
                                    </Button>
                                </Box>

                                {/*<Box p={1}>
                                    <Button component={Link} to="/crypto" style={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '110px'}}>
                                        CRYPTO
                                    </Button>
                                </Box>*/}

                                {/*admin &&
                                <Box>
                                    <Button component={Link} to="/admin" style={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '110px'}}>
                                        Admin
                                    </Button>
                                </Box>*/}
                            </Box>
                        </Grid2>

                        <Grid2  size={{xs:12, sm:12, md:6, lg:6, xl:6}}>
                            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                                <div style={{height: '300px', width: '300px'}}>
                                    {(assets.length > 0) &&
                                        <Balance data={assets}/>
                                    }
                                </div>
                            </Box>
                        </Grid2>
                    </Grid2>
                    </Paper>

                    <Box>
                        {depositLimit &&
                            <Paper elevation={2} sx={{padding: 2, margin:1}}>
                                <Typography variant='h5' fontWeight='bold' color='red'>
                                    {t("dashboard_legend1")}
                                </Typography>

                                <Typography color='red' variant='subtitle' gutterBottom>
                                    {t("dashboard_legend2")}
                                </Typography>
                            </Paper>}

                        <Paper elevation={2} sx={{padding:2, margin:1}}>
                            <Typography variant='h5' fontWeight='bold'>
                                {t("dashboard_transactions")}
                            </Typography>

                            <List>
                                {transactions.map( (t, i) => {
                                    return <ListItem key={i}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <PriceCheck/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={`${t.type.toUpperCase()}: ${USDollar.format(t.amount)} USD`}
                                            secondary={new Date(t.createdAt).toLocaleString()}
                                        />
                                    </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </div>
    )
}