import React from 'react';
//import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Typography, Grid2, Box, Button} from "@mui/material";
import {Link} from "react-router-dom";
import logo from "../../assets/logo_sb.png";
import {fetchData} from "../../hooks/fetchData";
import {useTranslation} from "react-i18next";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";

const Deposit = () => {
    const [name, setName] = React.useState("");
    const [accNumber, setAccNumber] = React.useState("");
    const {t} = useTranslation();

    React.useEffect(() => {
        fetchData().then((data) => {
            setName(`${data.name} ${data.lastName}`);
            setAccNumber(data.account_number);
        });
    }, []);

    return <div>
        {/*<Header/>*/}
        <ResponsiveAppBar/>

        <Box display="flex" justifyContent='center' overflow="auto">
            <Box flexGrow={1} sx={{maxWidth: 1000, paddingTop: 2, paddingBottom: 2, minHeight: 'calc(100vh - 180px)'}}>

                <Box p={2}>
                    <Typography variant="h3">
                        {name}
                    </Typography>
                    <hr style={{
                        border: "2px solid #032A51",
                        maxWidth: '350px',
                        marginLeft: 0
                    }}/>

                    <Typography fontWeight="bold" gutterBottom>
                        {`SHN-${accNumber}`}
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                        {t("fund_deposit_title")}
                    </Typography>
                </Box>

                <Grid2 container spacing={2} sx={{backgroundColor: 'rgb(3, 42,81,1)'}}>
                    <Grid2 size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 6}}>
                        <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={logo} alt="logo" width="300px"/>
                        </Box>
                    </Grid2>
                    <Grid2 size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 6}}>
                        <Box sx={{
                            display: 'flex', justifyContent: 'center', flexDirection: 'column',
                            alignItems: "center", // Vertical align
                            height: '100%'
                        }}>

                            <Typography color="white" fontWeight="bold">
                                {t("fund_deposit_beneficiary")}
                            </Typography>

                            <Typography color="white" gutterBottom>
                                PERSONAL ACCOUNT PCB
                            </Typography>

                            <Typography color="white" fontWeight="bold">
                                CLABE
                            </Typography>
                            <Typography color='white' gutterBottom>
                                653180003810176854
                            </Typography>

                            <Typography color="white" fontWeight="bold">
                                {t("fund_deposit_reference")}
                            </Typography>
                            <Typography color="white" gutterBottom>
                                SHN-{accNumber}
                            </Typography>

                            {/*<Typography color="white" fontWeight="bold">
                                SWIFT CODE
                            </Typography>
                            <Typography paragraph color='white'>
                                XXX-XXX-XXX
                            </Typography>*/}

                        </Box>
                    </Grid2>
                </Grid2>

                <Box p={2} textAlign="center" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <Typography gutterBottom>
                        {t("fund_deposit_bottom_text")}
                    </Typography>
                    <Button component={Link} to='/fund' style={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '100px'}}>
                        BACK
                    </Button>
                </Box>

            </Box>
        </Box>

        <Footer/>
    </div>
}

export default Deposit;