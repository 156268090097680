import React from "react";
import {
    Avatar,
    Box,
    Button, Divider,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    MenuList,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import Bitcoin from "../../assets/bitcoin.png";
import Ethereum from "../../assets/ethereum.png";
import Tether from "../../assets/tether.png";

const CriptoDashboard = () => {
    return <div>
        <Box>
            <Paper elevation={2} sx={{padding:2, margin: 2}}>
                <Typography  gutterBottom>
                    Tu saldo en criptomonedas
                </Typography>

                <Typography variant="h5" gutterBottom fontWeight="bold">
                    $ 0 USD
                </Typography>
                <Stack direction="row" spacing={1}>

                    <Button size="small" component={Link} to="/crypto/choose-crypto/buy" sx={{background: 'rgba(3, 42, 81, 1)', color: 'white'}}>
                        Comprar
                    </Button>
                    {/*<Button size="small" component={Link} to="/crypto/choose-crypto/buy" sx={{background: 'rgba(3, 42, 81, 1)', color: 'white'}}>
                        Vender
                    </Button>*/}
                    <Button size="small" component={Link} to="/crypto/choose-crypto/receive" sx={{background: 'rgba(3, 42, 81, 1)', color: 'white'}}>
                        Recibir
                    </Button>
                    {/*<Button size="small" component={Link} to="/crypto/transfer" sx={{background: 'rgba(3, 42, 81, 1)', color: 'white' }}>
                        Transferir
                    </Button>*/}

                </Stack>

                <Box paddingTop={2}>
                    <Button size="small" sx={{background: 'rgba(3, 42, 81, 1)', color: 'white'}} component={Link} to='/'>
                        Regresar
                    </Button>
                </Box>

            </Paper>

            <Paper elevation={2} sx={{padding: 0, margin: 2}}>
                <MenuList>
                    <ListItemButton component={Link} to='/crypto/btc'>
                        <ListItemAvatar>
                            <Avatar src={Bitcoin}/>
                        </ListItemAvatar>

                        <ListItemText primary="Bitcoin"
                                      secondary={'BTC 0.00'}
                        />
                        <Typography>
                            $ 0 USD
                        </Typography>
                    </ListItemButton>
                    <Divider/>
                    <ListItemButton component={Link} to='/crypto/eth'>
                        <ListItemAvatar>
                            <Avatar src={Ethereum}/>
                        </ListItemAvatar>
                        <ListItemText primary="Ethereum"
                                      secondary={'ETH 0.00'}
                        />

                        <Typography>
                            $ 0 USD
                        </Typography>
                    </ListItemButton>
                    <Divider/>
                    <ListItemButton component={Link} to='/crypto/usdt'>
                        <ListItemAvatar>
                            <Avatar src={Tether}/>
                        </ListItemAvatar>
                        <ListItemText primary="Tether"
                                      secondary={'USDT 0.00'}
                        />
                        <Typography>
                            $ 0 USD
                        </Typography>
                    </ListItemButton>
                </MenuList>
            </Paper>
        </Box>
    </div>
}

export default CriptoDashboard;