import React from 'react';
//import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import {Typography, Button, Box, Grid2, FormControl, Input, InputAdornment} from "@mui/material";
import {fetchBalance} from "../../hooks/fetchData";
import './Invest.css';
import InvestDialog from "./InvestDialog";
import {USDollar} from "../../hooks/format";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";

export default function InvestCashFlow () {

  const [name, setName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [available, setAvailable] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [amount, setAmount] = React.useState(0);

  React.useEffect(() => {
    fetchBalance().then(data => {
      setName(data.name);
      setLastName(data.lastName);
      setAvailable(data.available);
    }).catch(error => {
      console.log(error);
      setName('');
      setLastName('');
      setAvailable('')
    })
  }, []);

  const showTerms = () => {
    setOpen(true);
  }

  return (
      <div>
        {/*<Header />*/}
        <ResponsiveAppBar/>
        <Box display="flex" justifyContent='center'>
          <Box flexGrow={1} sx={{maxWidth: 1000, paddingTop: 2, paddingBottom: 2, minHeight: 'calc(100vh - 180px)'}}>

            <Grid2 container spacing={2}>
              <Grid2 size={{xs: 12, sm: 12, md:12, lg:6, xl:6}}>
                <Box p={2}>
                  <Typography variant='h3' paragraph>
                    {`${name} ${lastName}`}
                  </Typography>

                  <hr style={{
                    border: "2px solid #032A51",
                  }}/>

                </Box>
              </Grid2>

              <Grid2 size={{xs: 12, sm: 12, md: 12, lg: 6, xl:6}}>
                <Box p={2}>
                  <Typography variant='h4' fontWeight='bold'>
                    Cash Flow
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>

            <Grid2 container spacing={2}>
              <Grid2 size={{xs: 12, sm:12, md: 12, lg:6, xl:6}}>

                <Box p={2}>
                  <Box className='card_title' display='flex' justifyContent="center" alignItems="center">
                    <h3>Available</h3>
                  </Box>

                  <Box className='card' display='flex' justifyContent="center" alignItems="center">
                    <h1> {`${USDollar.format(available)} USD`} </h1>
                  </Box>

                </Box>
              </Grid2>
              <Grid2 size={{xs:12, sm: 12, md:12, lg:6, xl:6}}>
                <Box p={2}>

                  <Box className="card_title"  display='flex' justifyContent="center" alignItems="center">
                    <h3>Invest</h3>
                  </Box>

                  <Box sx={{
                    backgroundColor: 'rgba(3, 42, 81, 0.4)',
                    height: '130px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "center",
                    marginBottom: 1
                  }}>

                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                      <Input id="standard-adornment-amount"
                             type="number"
                             value={amount}
                             onChange={ (e) => {
                               setAmount(e.target.value)
                             }}
                             startAdornment={<InputAdornment
                                 position="start">
                               <Typography sx={{
                                 color: 'white', fontSize: '34px'
                               }}>
                                 $
                               </Typography>
                             </InputAdornment>}
                             sx={{
                               color: 'white',
                               fontSize: '34px'
                             }}
                             disableUnderline
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Box p={2}>
                  <Button onClick={showTerms} style={{ background: 'rgba(3, 42, 81, 1)', color: 'white', width: '100px'}}>
                    Invest
                  </Button>
                </Box>
              </Grid2>
            </Grid2>

            <Box p={2}>
              <Button component={Link} to='/invest' style={{ background: 'rgba(3, 42, 81, 1)', color: 'white', width: '100px'}}>
                Back
              </Button>
            </Box>
          </Box>
        </Box>


        <Footer />
        <InvestDialog open={open} setOpen={setOpen} checked={checked} setChecked={setChecked}
                      available={available} amount={amount} type="cash_flow"
        />
      </div>
  )
}
