import React from 'react';
import { Box } from '@mui/material';
import {Outlet} from 'react-router-dom';
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import NewWalletDialog from "./QrDialog";
import Footer from "../../components/Footer";

const Crypto = () => {
    const [open, setOpen] = React.useState(false);

    const handleOpenDialog = () => {
        //alert("Coming soon!");
        setOpen(true);
    }

    return <div style={{backgroundColor: "#ededed"}}>
        <ResponsiveAppBar/>
        <Box display="flex" justifyContent="center">
            <Box flexGrow={1} sx={{maxWidth: 1000, minHeight: 'calc(100vh - 180px)', paddingTop: 2, paddingBottom: 2}}>
                <Outlet/>
            </Box>
        </Box>

        <NewWalletDialog open={open} setOpen={setOpen}/>
        <Footer/>
    </div>
}

export default Crypto