import {
    Avatar,
    Divider,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    MenuList,
    Paper,
    Typography,
    Box,
    Button
} from "@mui/material";

import {Link, useParams} from "react-router-dom";
import Bitcoin from "../../assets/bitcoin.png";
import Ethereum from "../../assets/ethereum.png";
import Tether from "../../assets/tether.png";
import React from "react";
import {useNavigate} from "react-router-dom";

const ChooseCrypto = () => {
    const {action} = useParams();
    const navigate = useNavigate();

    const handleAction =  (cc) => {
        //redirect

        if (action === "buy"){
            navigate("/crypto/buy/cc");
        } else if (action === "receive"){
            navigate("/crypto/receive/cc");
        } else {
            alert(`${action}: ${cc}`);
        }

        /*switch (action) {
            case "buy":
                console.log(action);
                navigate("/crypto/buy");
                break;
            case "sell":
                console.log(action);
                navigate("/crypto/sell");
                break;
            case "receive":
                console.log(action);
                navigate("/crypto/receive");
                break;
            default:
                break;
        }*/
    }

    return (
        <div>
            <Box>
                <Typography fontWeight="bold" gutterBottom textAlign="center">
                    Elija la criptomoneda
                </Typography>

                <Paper elevation={2} sx={{padding: 0, margin: 2}}>
                    <MenuList>
                        <ListItemButton onClick={ () => handleAction("btc")} >
                            <ListItemAvatar>
                                <Avatar src={Bitcoin}/>
                            </ListItemAvatar>

                            <ListItemText primary="Bitcoin"
                                          secondary={'BTC 0.00'}
                            />
                            <Typography>
                                $ 0 USD
                            </Typography>
                        </ListItemButton>
                        <Divider/>
                        <ListItemButton onClick={ () => handleAction("eth")}>
                            <ListItemAvatar>
                                <Avatar src={Ethereum}/>
                            </ListItemAvatar>
                            <ListItemText primary="Ethereum"
                                          secondary={'ETH 0.00'}
                            />

                            <Typography>
                                $ 0 USD
                            </Typography>
                        </ListItemButton>
                        <Divider/>
                        <ListItemButton onClick={ () => handleAction("usdt")}>
                            <ListItemAvatar>
                                <Avatar src={Tether}/>
                            </ListItemAvatar>
                            <ListItemText primary="Tether"
                                          secondary={'USDT 0.00'}
                            />
                            <Typography>
                                $ 0 USD
                            </Typography>
                        </ListItemButton>
                    </MenuList>
                </Paper>
                <Box p={2}>
                    <Button component={Link} to="/crypto" sx={{background: 'rgba(3, 42, 81, 1)', color: 'white',}}>
                        Regresar
                    </Button>
                </Box>
            </Box>
        </div>
    );
}

export default ChooseCrypto;