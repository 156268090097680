import React from 'react';
import {Paper, Typography, Stack, Button} from "@mui/material";
import {Link} from "react-router-dom";
import {USDollar} from "../../../hooks/format";

const DashboardBTC = () => {
    const [btc, setBTC] = React.useState(0.00);

    React.useEffect(() => {
            const url = `wss://streamer.cryptocompare.com/v2?api_key=0ec4aa1c301d7e3a2f519f7a523a97acc86ae9e09a9c6cb16bd15d9508a6bc9b`;
            const ws = new WebSocket(url);
            const subscription = {
                "action": "SubAdd",
                "subs": ["5~CCCAGG~BTC~USD"]
            };

            ws.onopen = () => {
                console.log("Connection opened");
                ws.send(JSON.stringify(subscription));
            }

            ws.onmessage = (event) => {
                const response = JSON.parse(event.data);
                setBTC(response.PRICE)
                //console.log(response);
            }

            ws.onclose = () => {
                console.log("Connection Closed!");
            };

            ws.onerror = () => {
                console.log("WS Error");
            };

            return () => {
                //ws.close();
            };

    }, []);
    return (<div>
        <Paper elevation={2} sx={{padding: 2, margin: 2}}>
            <Typography>
                Tienes
            </Typography>
            <Typography>
                $ 0.00 USD
            </Typography>
            <Typography gutterBottom>
                BTC
            </Typography>

            <Stack direction="row">
                <Button component={Link} to="/crypto" style={{background: 'rgba(3, 42, 81, 1)', color: 'white',}}>
                    Regresar
                </Button>
            </Stack>
        </Paper>

        <Paper elevation={2} sx={{padding: 2, margin: 2}}>
            <Typography> Precio actual del Bitcoin</Typography>
            <Typography> {USDollar.format(btc)} USD</Typography>
        </Paper>

    </div>);
}

export default DashboardBTC;