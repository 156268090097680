import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/logo_azul_sb.png';
import {Box, Button, Typography} from "@mui/material";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import Footer from "../../components/Footer";

const InvestStatus = () =>  {
    return (
        <div>
            <ResponsiveAppBar />
            <Box display="flex" justifyContent="center">
                <Box flexGrow={1} sx={{maxWidth: 1000, minHeight: 'calc(100vh - 180px)', paddingTop: 2, paddingBottom: 2}}>
                    <Box p={2} textAlign="center">
                        <img src={logo} style={{maxWidth: '400px'}} alt='Logo'/>

                        <Typography variant='h5' gutterBottom>
                            SUCCESSFUL TRANSFER
                        </Typography>

                        <Typography>
                            Your money has been correctly invested, you can review it in investment within your profile.
                        </Typography>
                    </Box>

                    <Box m={2} textAlign="center">
                        <Button
                            component={RouterLink} to="/invest"
                            sx={{
                                margin: 1,
                                background: 'rgba(3, 42, 81, 1)',
                                color: 'white',
                                width: 150,
                                '&:hover': {
                                    backgroundColor: '#0069d9',
                                    borderColor: '#0062cc',
                                    boxShadow: 'none',
                                },
                            }}>
                            BACK
                        </Button>

                    </Box>
                </Box>
            </Box>
            <Footer/>
        </div>
    )
}

export default InvestStatus;