import React from 'react';
//import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import {Typography, Button, Grid2, Box} from "@mui/material";
import {fetchBalance} from "../../hooks/fetchData";
import './Invest.css';
import {useTranslation} from "react-i18next";
import {USDollar} from "../../hooks/format";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";

export default function Invest() {

  const [name, setName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [available, setAvailable] = React.useState('');
  const [cashFlow, setCashFlow] = React.useState('');
  const [longTerm, setLongTerm] = React.useState('');
  const {t} = useTranslation();

  React.useEffect(() => {
    fetchBalance().then(data => {
      setName(data.name);
      setLastName(data.lastName);
      setAvailable(data.available);
      setLongTerm(data.long_term);
      setCashFlow(data.cash_flow);
    }).catch(error => {
      console.log(error);
      setName('');
      setLastName('');
      setAvailable('')
    })
  }, []);

  return (
      <div>
        {/*<Header />*/}
          <ResponsiveAppBar/>
        <Box display="flex" justifyContent="center">
          <Box flexGrow={1} sx={{maxWidth: 1000, minHeight: 'calc(100vh - 180px)', paddingTop: 2, paddingBottom: 2}}>

            <Grid2 container spacing={2}>
              <Grid2 size={{xs: 12, sm: 12, md: 12, lg: 6, xl:6}}>
                <Box p={2}>
                  <Typography variant='h3' paragraph>
                    {`${name} ${lastName}`}
                  </Typography>

                  <hr style={{
                    border: "2px solid #032A51",
                  }}/>

                </Box>
              </Grid2>
              <Grid2 size={{xs: 12, sm: 12, md: 12, lg: 6, xl: 6}}>
                <Box p={2}>
                  <Typography variant="h5">
                    {t("invest_liquid_available")}
                  </Typography>
                  <Typography variant="h5" fontWeight='bold'>
                    {USDollar.format(available)} USD
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>

            <Grid2 container spacing={2}>
              <Grid2 size={{xs: 12, sm: 12, md: 12, lg:6, xl: 6}}>
                <Box p={2}>
                  <Box className='card_title' display='flex' justifyContent="center" alignItems="center">
                    <h3>{t("invest_cash_flow")}</h3>
                  </Box>

                  <Box className='card' display='flex' justifyContent="center" alignItems="center">
                    <h1>{USDollar.format(cashFlow)} USD</h1>
                  </Box>

                  <Typography paragraph>
                    {t("invest_cf_desc")}
                  </Typography>

                  <Button component={Link} to="/invest/cash-flow" style={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '100px'}}>
                    {t("invest_access")}
                  </Button>
                </Box>
              </Grid2>

              <Grid2 size={{xs: 12, sm:12, md:12, lg: 6, xl: 6}}>

                <Box p={2}>
                  <Box className='card_title' display='flex' justifyContent="center" alignItems="center">
                    <h3>{t("invest_long_term")}</h3>
                  </Box>

                  <Box className='card' display='flex' justifyContent="center" alignItems="center">
                    <h1> {USDollar.format(longTerm)} USD </h1>
                  </Box>

                  <Typography paragraph>
                    {t("invest_lt_desc")}
                  </Typography>

                  <Button component={Link} to="/invest/long-term" style={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '100px'}}>
                    {t("invest_access")}
                  </Button>
                </Box>

              </Grid2>

              <Grid2 size={12}>
                <Box p={2}>
                  <Button component={Link} to='/' style={{ background: 'rgba(3, 42, 81, 1)', color: 'white', width: '100px'}}>
                    {t("invest_back")}
                  </Button>
                </Box>
              </Grid2>
            </Grid2>

          </Box>
        </Box>

        <Footer />
      </div>
  )
}
