import React from 'react';
import Footer from '../../components/Footer';
//import Header from '../../components/Header';
import {Paper, Grid2, Box, Typography, Button, TextField} from '@mui/material';
import SideBar from './SideBar';
import {fetchData, updateProfile} from "../../hooks/fetchData";
import {useTranslation} from "react-i18next";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";

export default function Job() {
  const [employer, setEmployer] = React.useState('')
  const [jobTitle, setJobTitle] = React.useState('')
  const [yearlyIncome, setYearlyIncome] = React.useState(0);
  const [workEmail, setWorkEmail] = React.useState('');
  const [workPhone, setWorkPhone ] = React.useState('');
  const {t} = useTranslation();

  React.useEffect(() => {
    fetchData().then(data => {
      const {job_info} = data;
      setEmployer(job_info.employer || '')
      setJobTitle(job_info.job_title || '')
      setYearlyIncome(job_info.yearly_income || 0 )
      setWorkEmail(job_info.work_email || '')
      setWorkPhone(job_info.work_phone || '')
    }).catch(error => {
      console.log(error)
    })
  }, [])

  const handleSubmit = async () => {
    updateProfile({
      job_info: {
        employer: employer,
        job_title: jobTitle,
        yearly_income: yearlyIncome,
        work_email: workEmail,
        work_phone: workPhone,
      }
    }).then(data => {
      alert('Data updated successfully.')
      console.log(data);
    }).catch(err => {
      console.log(err);
    });
  }

  return (
      <div style={{backgroundColor: "#ededed"}}>
        {/*<Header />*/}
        <ResponsiveAppBar/>
        <Box display="flex" justifyContent="center">
          <Box flexGrow={1} sx={{maxWidth: 1000, paddingTop: 2, paddingBottom: 2, minHeight: 'calc(100vh - 180px)' }}>
            <Grid2 container spacing={2}>
              <Grid2 size={{xs: 12, sm:12, md:12, lg:6, xl:6}}>
                <SideBar section="job"/>
              </Grid2>

              <Grid2 size={{xs:12, sm: 12, md: 12, lg:6, xl:6}}>
                <Paper sx={{padding:1, margin: 1}} elevation={2}>
                <Box p={1}>
                  <Typography variant='h5'>
                    {t("myprofile_job_title")}
                  </Typography>
                </Box>
                <form>
                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_job_employer")}
                    </Typography>
                    <TextField placeholder="EMPLOYER" name="name" value={employer}
                               onChange={e => setEmployer(e.target.value)}
                               fullWidth size="small"
                    />
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_job_job_title")}
                    </Typography>
                    <TextField placeholder="JOB TITLE" name="job_title" value={jobTitle}
                               onChange={(e) => setJobTitle(e.target.value)}
                               fullWidth size="small"
                    />
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_job_yearly_income")}
                    </Typography>
                    <TextField type='number' placeholder="YEARLY INCOME" name="yearly_income" value={yearlyIncome}
                               onChange={e => setYearlyIncome(+e.target.value)} fullWidth
                               size="small"
                    />
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_job_work_email")}
                    </Typography>
                    <TextField placeholder="Work Email" name="email" value={workEmail}
                               onChange={(e) => setWorkEmail(e.target.value)}
                               fullWidth size="small"
                    />
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_job_work_phone")}
                    </Typography>
                    <TextField placeholder="Phone Office" name="phone" value={workPhone}
                               onChange={(e) => setWorkPhone(e.target.value)}
                               fullWidth size="small"
                    />
                  </Box>

                  <Box p={1}>
                    <Button type='button' style={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '300px'}} onClick={() => handleSubmit()}>
                      {t("myprofile_job_send")}
                    </Button>
                  </Box>
                </form>
                </Paper>
              </Grid2>
            </Grid2>
          </Box>
        </Box>
        <Footer />
      </div>
  )
}
