import React, { useState } from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Popup from '../components/Popup'
import logo from '../assets/logo_azul_sb.png'
import ia from '../assets/a.png'
import {Grid2, Box, Button, Link, Paper, TextField} from "@mui/material";
import {useAuth} from "../hooks/useAuth";
import HeaderNA from "../components/HeaderNA";
import Footer from '../components/Footer'
import {useTranslation} from "react-i18next";
//import ActionAlert from "./ActionAlert";

export default function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorResponse, setErrorResponse] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    /*const [alertData, setAlertData] = useState({
        visible: false,
        message: "",
        severity: "",
    });*/

    const {login} = useAuth();
    const {t} = useTranslation();

    async function handleSubmit(e) {
        e.preventDefault();

        login({email, password}).catch((error) => {
            console.log(error);
            setErrorResponse(error.message);
            setIsPopupOpen(true);  // Muestra el popup cuando hay un error
        });
    }

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <div style={{backgroundColor: "#ededed"}}>
            <HeaderNA/>
            <Box display="flex" justifyContent="center">
                <Box flexGrow={1} sx={{ maxWidth: 1100, minHeight: 'calc(100vh - 230px)'}}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, sm: 12, md:6, lg:6, xl:6}} justify="center">
                            <Box p={1}
                                 textAlign='center'
                                 sx={{
                                     paddingTop: 10,
                                     paddingBottom: 10
                                 }}
                            >

                                <Paper elevation={2} sx={{padding: 2}}>
                                    <img style={{width: '300px'}} src={logo} alt='Logo'/>
                                    <form onSubmit={handleSubmit}>
                                        <Box p={1}>
                                            <TextField placeholder={t("sign_in_email")} type='email' name="email" value={email}
                                                       onChange={(e) => setEmail(e.target.value)}
                                                       required
                                                       fullWidth size="small"/>

                                        </Box>

                                        <Box p={1}>
                                            <TextField placeholder={t("sign_in_password")} type='password' name="password" value={password}
                                                       onChange={(e) => setPassword(e.target.value)}
                                                       required
                                                       fullWidth size="small"/>
                                        </Box>

                                        <Box p={2}>
                                            <Button type='submit' style={{background: 'rgba(3, 42, 81, 1)', color: 'white', minWidth: 150}}>
                                                {t("sign_in_sign_in")}
                                            </Button>
                                        </Box>
                                    </form>

                                    <Link component={RouterLink}
                                          to='/auth/sign-up'
                                          sx={{
                                              color: 'rgba(3,42,81,1)',
                                              textDecoration: 'none'
                                          }}
                                    >
                                        {t("sign_in_create_account")}
                                    </Link>

                                    {/*<ActionAlert visible={alertData.visible} message={alertData.message} severity={alertData.severity}/>*/}
                                </Paper>
                            </Box>

                        </Grid2>
                        <Grid2 size={{xs: 12, sm: 12, md:6, lg:6, xl:6}}>
                            <Box display="flex" alignItems="center" justifyContent="center"  sx={{ height: '100%' }}>
                                <img src={ia} alt='Logo' style={{width: '100%', maxWidth: '350px'}}/>
                            </Box>
                        </Grid2>
                    </Grid2>
                </Box>
            </Box>

            <Footer/>
            <Popup isOpen={isPopupOpen} message={errorResponse} onClose={closePopup} />
        </div>
    )
}