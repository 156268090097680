import {Typography, Paper,Box, Chip} from "@mui/material";
import Bitcoin from "../../assets/bitcoin.png";
import SimpleBottomNavigation from "./btc/SimpleBottomNavigation";
import {useParams} from "react-router-dom";

const Receive = () => {
    const {currency} = useParams();
    const address = "bc1qevefghhfdsakiyrjdgjsadjhgrfdeegttylllmj";
    return (<div>
        <Box>
            <Paper elevation={2} sx={{padding: 2, margin: 2}}>
                <Box display="flex" justifyContent="center" flexDirection="column" textAlign="center" alignItems="center">
                    <img src={Bitcoin} alt="Bitcoin" style={{width: "50px", margin: "10px"}}/>

                    <Typography gutterBottom>
                        Esta es tu dirección de Prestige Capital Bank, debes usarla solo para recibir Bitcoin:
                    </Typography>

                    <Typography gutterBottom fontWeight="bold" sx={{wordBreak: "break-all"}}>
                        {address}
                    </Typography>

                    <Chip label="RED BITCOIN (BTC)"/>
                </Box>
            </Paper>

            <Paper elevation={2} sx={{padding: 2, margin: 2}}>
                <SimpleBottomNavigation/>
            </Paper>

            <Paper elevation={2} sx={{padding: 2, margin: 2}}>
                <Typography gutterBottom>
                    Debes usar únicamente la red Bitcoin (BTC) para no perder tus criptomonedas.
                </Typography>
                <Typography gutterBottom>
                    Las criptomonedas ingresan a tu cuenta en el plazo de una hora.
                </Typography>
            </Paper>
        </Box>
    </div>)
}

export default Receive;