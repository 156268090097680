import React from 'react';
import {Grid2, Typography, TextField, Paper, Button, Box, MenuList, ListItemButton, ListItemText, ListItemAvatar, Avatar} from "@mui/material";
import Footer from "../Footer";
import Person from "@mui/icons-material/Person"
import {fetchData} from "../../hooks/fetchData";
import {Navigate} from "react-router-dom";
import Client from "./Client";
import {fetchClients} from "../../hooks/admin";
import ResponsiveAppBar from "../ResponsiveAppBar";

const Admin = () => {
    const [user, setUser] = React.useState(null);
    const [results, setResults] = React.useState([]);
    const [client, setClient] = React.useState(null);

    const [query, setQuery] = React.useState({
        name: "",
        lastName: "",
        email: "",
        //account: "",
    });

    React.useEffect(() => {
        fetchData().then(data => {
            setUser(data);
        });
    }, []);

    const handleSearch = () => {
        fetchClients(query).then((clients) => {
            setResults(clients);
        })
    };

    const handleSelect = (e, client) => {
        setClient(client);
    };

    const handleSetValue = (e) =>{
        setQuery({
            ...query,
            [e.target.name]: e.target.value,
        })
    }

    return (<div style={{backgroundColor: "#ededed"}}>
        <ResponsiveAppBar/>
        {user && user.admin === false && (<Navigate to="/dashboard"/>)}

        <Box display="flex" justifyContent="center">
            <Box flexGrow={1} sx={{maxWidth: 1000, paddingTop: 2, paddingBottom: 2, minHeight: 'calc(100vh - 180px)'}}>

                <Paper sx={{ padding:2}} elevation={2}>
                    <Typography variant="h5" gutterBottom>
                        Buscar clientes
                    </Typography>
                    <Box p={1}>
                        <Typography fontWeight="bold">Nombre(s)</Typography>
                        <TextField placeholder="Nombre(s)" fullWidth size="small" name="name"
                                   value={query.name} onChange={e => handleSetValue(e)}
                        />
                    </Box>
                    <Box p={1}>
                        <Typography fontWeight="bold">Apellido</Typography>
                        <TextField placeholder="Apellido" fullWidth size="small" name="lastName"
                                   value={query.lastName} onChange={e => handleSetValue(e)}
                        />
                    </Box>
                    <Box p={1}>
                        <Typography fontWeight="bold">Email</Typography>
                        <TextField placeholder="Email" fullWidth size="small" name="email"
                                   value={query.email} onChange={e => handleSetValue(e)}
                        />
                    </Box>
                    {/*<Box p={1}>
                        <Typography fontWeight='bold'>Número de cuenta</Typography>
                        <TextField placeholder="Número de cuenta" fullWidth size="small"/>
                    </Box>*/}

                    <Box p={1}>
                        <Button variant="outlined" onClick={handleSearch}>
                            Buscar
                        </Button>
                    </Box>
                </Paper>

                <Grid2 container spacing={2}>
                    <Grid2 size={{xs: 12, sm:12, md:12, lg: 6, xl: 6}}>

                        <Paper sx={{padding: 2, marginTop: 2}} elevation={2}>
                            <Typography fontWeight="bold" gutterBottom>
                                Resultados
                            </Typography>
                            {results && results.length > 0 &&

                                <MenuList>
                                    {results.map((r, i) => {
                                        return <ListItemButton key={i} onClick={e => handleSelect(e, r)}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <Person/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`SHN-${r.account_number}: ${r.name} ${r.lastName}`}
                                                secondary={`Email: ${r.email}`}
                                            />
                                        </ListItemButton>
                                    })}
                                </MenuList>
                            }
                        </Paper>
                    </Grid2>
                    <Grid2 size={{ xs:12, sm:12, md: 12, lg: 6, xl:6}}>
                        <Paper sx={{padding: 2, marginTop: 2}} elevation={2}>
                            <Typography fontWeight="bold" gutterBottom>
                                Selecciona un cliente para modificar su información
                            </Typography>
                            {client && <Client client={client} agent_id={user._id}/>}
                        </Paper>
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
        <Footer/>
    </div>)
}

export default Admin;