import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/logo_azul_sb.png';
import {Box, Button, Link, Typography} from "@mui/material";
import Footer from '../../components/Footer';
import ResponsiveAppBar from "../../components/ResponsiveAppBar";

export default function Transfer() {
    return (
        <div>
            <ResponsiveAppBar/>
            <Box sx={{minHeight: 'calc(100vh - 180px)'}} flexGrow={1}>

                <Box p={2} textAlign="center">
                    <img src={logo} style={{maxWidth: '300px', width: '100%'}} alt='Logo'/>
                    <Typography variant='h5'>
                        SELECT TRANSACTION
                    </Typography>
                </Box>

                <Box m={2} textAlign="center">
                    <Button
                        component={RouterLink} to='/transfer/domestic'
                        sx={{
                            margin: 1,
                            background: 'rgba(3, 42, 81, 1)',
                            color: 'white',
                            width: 150,
                            /*'&:hover': {
                                backgroundColor: '#0069d9',
                                borderColor: '#0062cc',
                                boxShadow: 'none',
                            },*/
                        }}>
                        Domestic
                    </Button>

                    <Button
                        component={RouterLink} to='/transfer/international'
                        sx={{
                            margin: 1,
                            width: 150,
                            background: 'rgba(3, 42, 81, 1)',
                            color: 'white'
                            /* '&:hover': {
                                 backgroundColor: '#0069d9',
                                 borderColor: '#0062cc',
                                 boxShadow: 'none',
                             },*/
                        }}>
                        International
                    </Button>
                </Box>

                <Box p={2} textAlign='center'>
                    <Link component={RouterLink} to="/" style={{ color: 'white', fontWeight: 'bold', textDecoration: 'none' }}>
                        Back
                    </Link>
                </Box>

                <Box p={2} textAlign='center'>
                    <Typography style={{color: 'white'}}>
                        PRESTIGE CAPITAL BANK - ALL RIGHTS RESERVED
                    </Typography>
                </Box>

            </Box>
            <Footer/>
        </div>
    )
}