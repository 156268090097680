import React from 'react';
import Footer from '../../components/Footer';
import {fetchData, updateProfile} from "../../hooks/fetchData";
import {Paper, Box, Button, Grid2, Typography, TextField, Input} from "@mui/material";
import SideBar from "./SideBar";
import {useTranslation} from "react-i18next";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";

export default function Identity() {
  const [idNumber, setIdNumber] = React.useState('');
  const [passportNumber, setPassportNumber] = React.useState('');
  const [taxID, setTaxID] = React.useState('');
  const [day, setDay] = React.useState('');
  const [month , setMonth] = React.useState('');
  const [year, setYear] = React.useState('');
  const [file1, setFile1]=React.useState(null);
  const [file2, setFile2] = React.useState(null);
  const [file3, setFile3] = React.useState(null);
  const {t} = useTranslation();

  React.useEffect( () => {
    fetchData().then(data => {
      const {identity_info, birth_date} = data;
      setIdNumber(identity_info.id_number || '')
      setPassportNumber(identity_info.passport_number || '')
      setTaxID(identity_info.tax_id ||'')
      setDay(birth_date.day ||'')
      setMonth(birth_date.month ||'')
      setYear(birth_date.year ||'')
    }).catch(error => {
      console.log(error);
      // TODO: reload if 401
    })
  },[]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    updateProfile({
      identity_info: {
        id_number: idNumber,
        passport_number: passportNumber,
        tax_id: taxID,
      },
      birth_date:{
        day: day,
        month: month,
        year: year
      }
    }).then(data => {
      alert('Data updated successfully.');
      console.log(data);
    }).then(err => {
      console.log(err);
    });

    const upload_url = `${process.env.REACT_APP_SERVER_URL}/api/user/file-upload`;
    try {
      let fd = new FormData();
      fd.append('file1', file1);
      fd.append('file2', file2);
      fd.append('file3', file3);

      const rsp = await fetch(upload_url, {
        method: 'POST',
        headers: {},
        credentials: 'include',
        body: fd
      });

      const data = await rsp.json();
      console.log(data);
      //mostrar mensaje de datos actualizados
    } catch (e){
      console.log(e);
      //mostrar mensaje de error
    }
  }

  return (<div style={{backgroundColor: "#ededed"}}>
    {/*<Header />*/}
    <ResponsiveAppBar/>

    <Box display="flex" justifyContent='center'>
      <Box flexGrow={1} sx={{maxWidth: 1000, paddingTop: 2, paddingBottom: 2, minHeight: 'calc(100vh - 180px)' }}>
        <Grid2 container spacing={2}>
          <Grid2 size={{xs: 12, sm:12, md: 12, lg: 6, xl: 6}}>
            <SideBar section="identity" />
          </Grid2>

          <Grid2 size={{xs:12, sm:12, md:12, lg:6, xl:6}}>
            <Paper sx={{padding:1, margin: 1}} elevation={2}>
              <Box p={1}>
                <Typography variant="h5">
                  {t("myprofile_identity_title")}
                </Typography>
              </Box>

              <form onSubmit={e => handleSubmit(e)}>
                <Box p={1}>
                  <Typography fontWeight="bold">
                    {t("myprofile_identity_id")}
                  </Typography>
                  <TextField placeholder="ID NUMBER" name="id_number" value={idNumber}
                             onChange={e => setIdNumber(e.target.value)} fullWidth size="small"/>
                </Box>

                <Box p={1}>
                  <Typography fontWeight="bold">
                    {t("myprofile_identity_passport")}
                  </Typography>
                  <TextField placeholder="PASSPORT #" name="passport_number" value={passportNumber}
                             onChange={e => setPassportNumber(e.target.value)} fullWidth size="small"/>
                </Box>

                <Box p={1}>
                  <Typography fontWeight="bold">
                    {t("myprofile_identity_taxid")}
                  </Typography>
                  <TextField placeholder="TAX ID" name="tax_id" value={taxID} onChange={e => setTaxID(e.target.value)} fullWidth size="small"/>
                </Box>

                <Box p={1}>
                  <Typography fontWeight="bold">
                    {t("myprofile_identity_dob")}
                  </Typography>
                </Box>


                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  <Box p={1}>
                    <TextField placeholder="Day" name="day" value={day} onChange={e => setDay(e.target.value)}
                               style={{width: '120px'}} size="small"
                    />
                  </Box>
                  <Box p={1}>
                    <TextField placeholder="Month" name="month" value={month} onChange={e => setMonth(e.target.value)}
                               style={{width: '120px'}} size="small"
                    />
                  </Box>
                  <Box p={1}>
                    <TextField placeholder="Year" name="year" value={year} onChange={e => setYear(e.target.value)}
                               style={{width: '120px'}} size="small"
                    />
                  </Box>
                </Box>

                <Box p={1}>
                  <Typography fontWeight="bold">
                    {t("myprofile_identity_personal_identification")}
                  </Typography>
                  <Box p={1}>
                    <Input type='file' placeholder="PERSONAL IDENTIFICATION (FRONT)" name="identification1"
                           onChange={e => setFile1(e.target.files[0])} required={true} style={{marginBottom: '20px'}}/>

                    <Input type='file' placeholder="PERSONAL IDENTIFICATION (BACK)" name="identification2"
                           onChange={e => setFile2(e.target.files[0])} required={true}/>
                  </Box>

                  <Typography variant="subtitle">
                    {t("myprofile_identity_personal_identification_desc")}
                  </Typography>
                </Box>

                <Box p={1}>
                  <Typography fontWeight="bold">
                    {t("myprofile_identity_identity_verification")}
                  </Typography>
                  <Box p={1}>
                    <Input type='file' placeholder="IDENTITY VERIFICATION" name="identity"
                           onChange={e => setFile3(e.target.files[0])} required={true}/>
                  </Box>

                  <Typography variant="subtitle">
                    {t("myprofile_identity_identity_verification_desc")}
                  </Typography>
                </Box>

                <Box p={1}>
                  <Button type='submit'
                          style={{
                            background: 'rgba(3, 42, 81, 1)',
                            color: 'white',
                            width: '300px',
                            marginTop: '20px'
                          }}>
                    {t("myprofile_identity_send")}
                  </Button>
                </Box>
              </form>
            </Paper>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
    <Footer/>
  </div>)
}
