import React, {useState} from 'react';
import Footer from '../../components/Footer';
//import Header from '../../components/Header';
//import Popup from '../components/Popup'  // Importa el componente Popup
import {Link as RouterLink} from 'react-router-dom'
import {fetchData} from "../../hooks/fetchData";
import {useNavigate} from "react-router-dom";
import b from '../../assets/b.png';
import {Paper, Box, Button, Grid2, Typography, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";

const MyProfile = () => {
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const {t} = useTranslation();
    const navigate = useNavigate();

    React.useEffect(() => {
        // set data
        fetchData().then(data => {
            const {birth_date} = data;
            setName(data.name);
            setLastName(data.lastName);
            setEmail(data.email);
            setPhone(data.phone);
            setDay(birth_date.day);
            setMonth(birth_date.month);
            setYear(birth_date.year);
        }).catch(error => {
            console.log(error);
            navigate('/auth/log-in');
        });
    });

    /*const [isPopupOpen, setIsPopupOpen] = useState(false);
    const closePopup = () => {
        setIsPopupOpen(false);
    };*/

    return (<div style={{background: '#ededed'}}>
            {/*<Header />*/}
            <ResponsiveAppBar/>
            <Box display="flex" justifyContent='center'>
                <Box flexGrow={1} sx={{maxWidth: 1000, paddingTop: 2, paddingBottom: 2, minHeight: 'calc(100vh - 180px)'}}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{xs:12, sm:12, md:12, lg:6, xl:6}}>
                            <Box display="flex" alignItems="center" justifyContent="center"  sx={{ height: '100%' }}>
                                <img src={b} alt='logo' style={{maxWidth: '350px'}}/>
                            </Box>
                        </Grid2>

                        <Grid2  size={{xs:12, sm:12, md:12, lg:6, xl:6}}>

                            <Paper sx={{padding:1, margin: 1}} elevation={2}>
                                <Box p={1}>
                                    <Typography variant='h5'>
                                        {t("myprofile_title")}
                                    </Typography>
                                </Box>
                                <form>
                                    <Box p={1}>
                                        <Typography fontWeight="bold">
                                            {t("myprofile_name")}
                                        </Typography>
                                        <TextField placeholder="Name" type='text' name="name" value={name} disabled fullWidth size="small"/>
                                    </Box>

                                    <Box p={1}>
                                        <Typography fontWeight="bold">
                                            {t("myprofile_lastname")}
                                        </Typography>
                                        <TextField placeholder="Last name" type='text' name="lastName" value={lastName} disabled fullWidth size="small"/>
                                    </Box>

                                    <Box p={1}>
                                        <Typography fontWeight="bold">
                                            {t("myprofile_email")}
                                        </Typography>
                                        <TextField placeholder="Email" type='email' name="email" value={email} disabled fullWidth size="small"/>
                                    </Box>

                                    <Box p={1}>
                                        <Typography fontWeight="bold">
                                            {t("myprofile_phone")}
                                        </Typography>
                                        <TextField placeholder="Phone" type='tel' name="phone" value={phone} disabled fullWidth size="small"/>
                                    </Box>

                                    <Box p={1}>
                                        <Typography fontWeight="bold">
                                            {t("myprofile_dob")}
                                        </Typography>
                                    </Box>


                                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                                        <Box p={1}>
                                            <TextField placeholder="Day" type='text' name="day" value={day} disabled style={{width: '120px'}} size="small"/>
                                        </Box>
                                        <Box p={1}>
                                            <TextField placeholder="Month" type='text' name="month" value={month} disabled style={{width: '120px'}} size="small"/>
                                        </Box>
                                        <Box p={1}>
                                            <TextField placeholder="Year" type='text' name="year" value={year} disabled style={{width: '120px'}} size="small"/>
                                        </Box>
                                    </Box>

                                    <Box p={1}>
                                        <Typography variant='h6' color='red' paragraph>
                                            {t("myprofile_legend")}
                                        </Typography>

                                        <Button type='button' className='mt-4'
                                                component={RouterLink}
                                                to='/profile/personal-info'
                                                style={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '300px'}}
                                        >
                                            {t("myprofile_increase")}
                                        </Button>
                                    </Box>

                                </form>
                            </Paper>
                        </Grid2>
                    </Grid2>
                </Box>
            </Box>
            <Footer/>
            {/*<Popup isOpen={isPopupOpen} message={errorResponse} onClose={closePopup} />*/}
        </div>
    );
}

export default MyProfile;
