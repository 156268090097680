import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import QrCodeIcon from "@mui/icons-material/QrCode";

export default function SimpleBottomNavigation() {
    const [value, setValue] = React.useState(0);

    return (
        <Box>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction label="Copiar" icon={<ContentCopyIcon />} />
                <BottomNavigationAction label="Compartir" icon={<ShareIcon />} />
                <BottomNavigationAction label="Abrir QR" icon={<QrCodeIcon />} />
            </BottomNavigation>
        </Box>
    );
}