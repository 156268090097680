import React from 'react';
import {Button, Paper, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";

const DashboardETH = () => {
    return (<div>
        <Paper elevation={2} sx={{padding: 2, margin: 2}}>
            <Typography>
                Tienes
            </Typography>
            <Typography>
                $ 0.00 USD
            </Typography>
            <Typography gutterBottom>
                ETH
            </Typography>

            <Stack direction="row">
                <Button component={Link} to="/crypto" style={{background: 'rgba(3, 42, 81, 1)', color: 'white',}}>
                    Regresar
                </Button>
            </Stack>
        </Paper>

        <Paper elevation={2} sx={{padding: 2, margin: 2}}>
            <Typography> Precio actual de Ethereum</Typography>
        </Paper>

    </div>);
}

export default DashboardETH;